import { Object3D } from 'three';
import { inject, onUnmounted, provide } from 'vue';

import { useThreeTransforms } from '@resn/gozer-vue';

export const useThreeOrthoObject = (
    obj,
    { addToParent = true, name = 'Object', props = null, root = false } = {}
) => {
    const object = obj || new Object3D();
    object.name = name;

    if (addToParent) {
        const addToOrtho = inject('addToOrtho', null);
        // console.log('🚀 ~ useThreeOrthoObject ~ parent:', addToOrtho?.name, 'name', name);

        if (addToOrtho) {
            addToOrtho.add(object);
        } else {
            const addTo = inject('addTo');
            addTo.add(object);
        }
    }

    onUnmounted(() => {
        object.parent?.remove(object);
    });

    if (root) {
        provide('addToOrtho', object);
    } else {
        provide('addTo', object);
    }

    if (props) {
        const newProps = useThreeTransforms(object, props);

        return { object, props: newProps };
    }

    return { object };
};
